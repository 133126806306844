.znd-search-input {
    position: relative;
    min-width: 10rem;

    & &__input {
        padding-left: 1.5rem;
        padding-right: 3.25rem;

        background-color: $white;
        border-radius: (($input-line-height * 1rem) / 2) + $input-padding-y + ($input-border-width / $font-size-root);

        // Disabled and read-only inputs
        // HTML5 says that controls under a fieldset > legend:first-child won't be
        // disabled if the fieldset is disabled. Due to implementation difficulty, we
        // don't honor that edge case; we style them as disabled anyway.
        &:disabled,
        &[readonly] {
            background-color: $gray-200;
        }

        &:hover,
        &:focus {
            background-color: $white;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 1.625rem;
        transform: translate(50%, -50%);

        line-height: 1;
        font-size: 1.125rem;

        pointer-events: none;

        :disabled + &,
        [readonly] + & {
            color: $input-disabled-color;
        }

        &--clear {
            pointer-events: auto;
            cursor: pointer;
        }
    }
}
