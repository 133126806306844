.znd-table-tile {
    padding: 1.25rem 0;
    margin: 1.25rem;
    border-radius: 0.375rem;
    background-color: $white;
    display: inline-block;
    width: calc(100% - 2.5rem);
    min-width: min-content;

    @include media-breakpoint-up(sm) {
        padding: 1.875rem 0;
        margin: 2.5rem;
        width: calc(100% - 5rem);
    }

    &__header {
        padding: 0 1.875rem 1.25rem;

        @include media-breakpoint-up(sm) {
            padding: 0.625rem 2.5rem 1.875rem;
        }

        &-title {
            color: $secondary;
        }
    }

    &__icon-caret,
    & &__icon-caret.icon-caret-down {
        color: $primary;
        margin-left: auto;
        display: block;
    }

    &__info {
        color: $primary;
        display: flex;

        &--dark {
            color: $dark;
        }

        .znd-tooltip__label {
            width: max-content;
            text-align: left;
        }
    }

    &__icon-info {
        display: inline-block;
        width: 1.375rem;
        height: 1.375rem;
        background-color: $primary;
        color: $white;
        border-radius: 50%;
        text-align: center;

        &--dark {
            display: flex;
            color: $dark;
            text-align: center;

            &-icon {
                font-size: 1.25rem;
            }
        }
    }

    &__name {
        pointer-events: none;

        &--with-icon {
            margin-right: 0.25rem;
        }
    }

    &--inner-scroll &__scroll-wrapper {
        overflow-x: auto;
        overflow-y: hidden;

        width: calc(100vw - 2.5rem);

        @include media-breakpoint-up(sm) {
            width: calc(100vw - 5rem);
        }

        @include media-breakpoint-up(xl) {
            width: calc(100vw - 11.5rem);
        }
    }

    &--inner-scroll &__header {
        margin-top: -1.875rem;
        padding-top: 1.675rem;

        background-color: #ffffff;

        position: sticky;
        top: 3.875rem;

        z-index: $zindex-sticky + 1;

        border-radius: 0.375rem;

        @include navbarOnDesktop {
            top: 0;
        }
    }
}
