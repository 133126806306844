@mixin button-variant(
    $background,
    $border,
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%),
    $active-background: darken($background, 10%),
    $active-border: darken($border, 12.5%)
) {
    // Just to avoid the default bootstrap styles
}

@mixin stretched() {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
