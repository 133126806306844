.znd-car {
    &__animation {
        &--rotate {
            transform-origin: center bottom;
            animation-name: carRotate;
            animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
        &--bump {
            animation-name: carBody;
            animation-duration: 0.4s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }
    }
}

@keyframes carBody {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(1%);
    }
}

@keyframes carRotate {
    0%,
    100% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(-0.375deg);
    }
}
