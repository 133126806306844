.znd-newsletter-and-terms {
    margin-bottom: 0.5rem;

    &__checkbox-option {
        list-style-type: none;
        margin: 0;
        padding: 0;
        cursor: pointer;

        &:hover {
            background-color: rgba($gray-100, 0.5);
        }
    }

    &__checkbox-label {
        @include t-body;
        color: $gray-500;
        margin: 0;
        cursor: pointer;

        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        .custom-control-label {
            &::before,
            &::after {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
