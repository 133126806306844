.znd-change-request-modal {
    &__select-header,
    &__input-header {
        font-weight: $font-weight-bold;
        margin-bottom: 0.375rem;
    }

    &__body {
        max-height: calc(100vh - 13.25rem);

        .required {
            color: $primary;
        }
        .form-control {
            margin-bottom: 1.25rem;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &__col {
        width: 100%;

        @include media-breakpoint-up(lg) {
            max-width: 49%;
        }
    }

    &__select {
        max-width: 50%;
        margin-bottom: 1.25rem;
    }

    &__select-country,
    &__select-state {
        width: 100%;
        max-width: 99%;
    }

    &__checkbox-label {
        padding-left: 1.75rem;
        margin-bottom: 1.25rem;
    }
}
