.znd-shop-search-result-page {
    &__content {
        margin: 1.25rem;

        @include media-breakpoint-up(lg) {
            margin: 1.875rem;
        }

        @include media-breakpoint-up(md) {
            margin: 2.5rem;
        }
    }

    &__header {
        width: 100%;
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 1rem;
        }

        &__title {
            margin-bottom: 0.25rem;
            color: $secondary;
        }

        &__subtitle {
            color: $gray-500;
        }

        &-right {
            margin-bottom: 0.25rem;
            margin-top: 1.25rem;

            @include media-breakpoint-up(sm) {
                margin: 0;
            }
        }
    }
}
