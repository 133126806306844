.znd-social-media {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: auto;
    padding: 2.5rem 0.625rem;

    &__link {
        display: inline-flex;
        position: relative;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 3.75rem;
        height: 3.75rem;
        border: 1px solid $gray-200;
        text-align: center;
        border-radius: 50%;
        margin: 0.25rem;
        background: $white;
        color: $gray-400;
        transition: color 0.4s ease-out;

        &:before {
            font-size: 1.125rem;
        }

        &:hover {
            color: $gray-500;
        }

        &.icon-instagram,
        &.icon-youtube,
        &.icon-twitter {
            &:before {
                font-size: 1.5rem;
            }
        }

        &.icon-facebook,
        &.icon-pinterest {
            &:before {
                font-size: 1.25rem;
            }
        }
    }
}
