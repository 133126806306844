.znd-change-company-profile-modal {
    &__body {
        padding-top: 2.5rem;
    }

    &__footer {
        padding-bottom: 2.375rem;
    }

    &__info-container {
        display: flex;
        flex-direction: row;
        border: 1px $secondary solid;
        border-radius: 0.375rem;
        padding: 1rem;
        background-color: $gray-100;
        margin-bottom: 2.5rem;
    }

    &__info-icon {
        font-size: 1.375rem;
    }

    &__info-text {
        font-weight: $font-weight-normal;
        font-size: $font-size-base;
        line-height: $line-height-base;
    }

    &__info-icon + &__info-text {
        margin-left: 0.625rem;
    }
}
