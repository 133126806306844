.znd-addresses-table-sub {
    margin: 1rem 1rem 1.5rem 4.125rem;

    &__column {
        font-size: 1rem;
        line-height: 1.5rem;
        min-width: 20%;
        max-width: 40%;
        width: auto;

        div + div {
            margin-top: 0.125rem;
        }
    }

    &__title {
        line-height: 1.375rem;
    }
}
