.znd-shop-checkout-payment-page {
    &__title {
        @include t-headline-3;
        color: $secondary;

        margin-bottom: 2.5rem;
    }

    &__button {
        width: 100%;
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex: 1 1 12.5rem;
            min-width: 12.5rem;
            margin-left: auto;
            width: auto;
        }
    }
}
