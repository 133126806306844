.znd-dashboard-cutter-info-text {
    height: 100%;
    padding: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 0.375rem;

    &__text {
        font-size: 1.125rem;
        color: $gray-500;
    }
}
