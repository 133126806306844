.znd-dashboard-shortcut-item {
    background-color: $white;
    border-radius: $border-radius;
    display: flex;
    padding: 1.25rem;

    & + & {
        margin-top: 0.625rem;
    }

    &__icon {
        margin-right: 1.25rem;
        border-radius: $border-radius;
        background-color: $gray-100;

        width: 3.125rem;
        height: 3.125rem;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 1.25rem;
        color: $gray-400;
    }

    &__text {
        align-self: center;
        flex: 1;
        min-width: 0;
        margin-right: 1.25rem;
    }

    &__text-title {
        color: $secondary;
        font-weight: $font-weight-bold;
    }

    &__text-body {
        color: $gray-500;
        overflow: hidden;
        margin-top: 0.25rem;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__link,
    &__link:hover {
        margin-left: auto;

        align-self: center;

        font-size: 1.5rem;
        text-decoration: none;
        color: $primary;
    }
}
