.znd-shop-checkout-shipping {
    &__headline {
        color: $secondary;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 2.5rem;
        }
    }

    &__addresses {
        $addressesComponent: &;

        margin-bottom: 3.75rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 4.25rem;
        }

        &__column {
            flex: 0 0 50%;
            margin-bottom: 2.75rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                flex: 0 0 45%;
            }

            &:last-child {
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    margin-left: auto;
                }
            }
        }

        &--summary {
            margin-bottom: 2.5rem;
            #{$addressesComponent}__column {
                margin-bottom: 2.5rem;
                &:last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(xl) {
                margin-bottom: 3rem;
            }
        }
    }

    &__button {
        width: 100%;
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex: 1 1 12.5rem;
            min-width: 12.5rem;
            margin-left: auto;
            width: auto;
        }
    }

    &__service-input {
        max-width: none;
    }

    &__change-address-text {
        color: $gray-400;
    }
}
