.znd-main-navigation-item {
    position: relative;
    display: flex;
    flex-direction: row;

    font-size: 1rem;
    line-height: 1.5rem;

    padding: 1.75rem 1.875rem;

    @include navbarOnDesktop {
        padding: 1.375rem 1.875rem;
    }

    &,
    &:hover {
        text-decoration: none;
        color: $gray-400;
    }

    &--active {
        &,
        &:hover {
            color: $primary;
        }
    }

    &__icon {
        font-size: 1.5rem;
        line-height: 0;
        margin-right: 1.875rem;
        transition: color 0.4s ease-out;
    }

    &__label {
        transition: color 0.4s ease-out;
        @include navbarOnDesktop {
            display: none;
        }
    }

    &::before {
        display: block;
        content: '';

        position: absolute;
        top: 50%;
        left: 0;

        height: 3.75rem;
        width: 3px;

        margin-top: -1.875rem;

        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;

        background-color: $primary;

        transform: translateX(-3px);
        transition: transform 0.2s ease-out;
    }

    &:hover::before,
    &--active::before {
        transform: translateX(0);
    }
}
