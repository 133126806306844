.znd-software-downloads {
    display: none;
    max-width: 64.75rem;
    margin: 0 auto 3.125rem;
    padding: 2.5rem 1.875rem;

    &--active {
        display: block;
    }

    &__title {
        color: $secondary;
        margin: 0 -0.75rem 1rem;
    }
}
