.znd-range-slider {
    position: relative;

    &__active-track {
        position: absolute;
        top: $custom-range-thumb-height / 2;
        left: 0;

        width: 100%;

        border-radius: $custom-range-track-border-radius;
        overflow: hidden;

        pointer-events: none;
    }

    &__active-bar {
        width: 100%;
        height: $custom-range-track-height;
        background-color: $custom-range-thumb-bg;
        transform-origin: left center;
    }

    &__input:disabled + &__active-track,
    &__input[disabled] + &__active-track {
        opacity: 0;
    }
}
