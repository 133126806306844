.znd-select {
    position: relative;
    display: flex;
    flex-direction: column;

    cursor: pointer;

    &__box {
        position: relative;
        width: fit-content;
        align-self: flex-end;

        background-color: $white;

        color: $secondary;
        font-weight: $font-weight-bold;

        padding: 0.625rem 2.375rem 0.625rem 1rem;
        border-radius: 1.25rem;
    }

    &__icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    &__options-wrapper {
        position: absolute;
        top: 100%;
        right: 0;

        z-index: $zindex-dropdown;

        opacity: 0;
        visibility: hidden;
    }

    &__options {
        position: relative;
        margin-top: 1rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        border-radius: 0.375rem;

        background-color: $white;
        box-shadow: $box-shadow;

        white-space: nowrap;

        &-item {
            position: relative;
            padding: 0.5rem 3.125rem 0.5rem 2.75rem;

            &:hover {
                background-color: $gray-100;
            }

            &-icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0.825rem;
                color: $primary;
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: 1.25rem;
            top: -0.5rem;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-bottom: 0.5rem solid $white;
        }
    }

    &:hover &__options-wrapper {
        opacity: 1;
        visibility: visible;
    }
}
