.znd-reseller-modal {
    &__select {
        max-width: 100%;
    }

    &__button {
        width: 100%;
        margin-bottom: 1.5rem;
        justify-content: center;

        @include media-breakpoint-up(md) {
            width: auto;
            margin-bottom: 0;
        }
    }
}
