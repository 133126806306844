.transition {
    &-fade {
        // Start of enter animation
        &-enter {
            opacity: 0;
        }

        // Start of exit animation
        &-exit {
            opacity: 1;
        }

        // Target of enter animation
        &-enter-active {
            opacity: 1;
        }

        // Target of exit animation
        &-exit-active {
            opacity: 0;
        }

        &-enter-active,
        &-exit-active {
            transition: opacity 0.2s ease-out;
        }
    }
}
