.btn {
    $component: &;

    position: relative;

    i {
        font-size: 1.5em;
    }

    &__icon-left {
        margin-right: 0.75rem;
    }

    &__icon-right {
        margin-left: 0.75rem;
    }

    &.--has-icon {
        padding: 0.875rem 5rem 0.875rem 1.5rem;

        i {
            position: absolute;
            top: 50%;
            right: 1.5rem;

            transform: translateY(-50%);
        }

        &.--btn-full-width {
            width: 100%;
            justify-content: space-between;

            @include media-breakpoint-up(lg) {
                width: auto;
            }
        }
    }

    &-sm {
        &.--has-icon {
            padding: 0.625rem 1rem;
            padding-right: 2.75rem;

            i {
                right: 0.625rem;
            }
        }
    }

    &-block {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        #{$component}__icon-left {
            margin-right: auto;
        }
        #{$component}__icon-right {
            margin-left: auto;
        }
    }

    &.--image-only,
    &.--icon-only {
        padding: 1.5rem;
        position: relative;
        border-radius: 50%;
    }

    &.--icon-only {
        i {
            font-size: 1.25rem;
            position: absolute;
            left: calc(50% - 10px);
            top: calc(50% - 10px);
        }
    }

    &.--image-only {
        position: relative;
        overflow: hidden;

        #{$component}__image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            width: 100%;

            object-fit: cover;
            object-position: center;

            transition: opacity 0.2s ease-out;
        }

        &:hover #{$component}__image {
            opacity: 0.8;
        }
    }

    &.--text {
        padding: 0;

        i {
            padding: 0;
            left: 0;
        }
    }

    &:disabled {
        opacity: 1;
    }

    & &__loader {
        position: absolute;
        top: 50%;
        right: 1.5rem;

        width: 1.25rem;
        height: 1.25rem;
        transform: translateY(-50%);

        path {
            stroke: currentColor;
            stroke-width: 6px;
        }
    }

    &#{&}-sm &__loader {
        right: 0.5rem;
    }

    &-navigator {
        background-color: $white;
        border-color: $gray-200;
        color: $primary;

        &:hover {
            color: $primary;
        }

        &:disabled {
            color: $gray-200;
            border-color: $gray-100;
        }
    }

    &-white {
        background-color: $white;
        border-color: $white;
        color: $secondary;

        &:hover {
            color: $secondary;
        }

        &:disabled {
            border-color: $white;
            background-color: $white;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &-gray {
        background-color: $gray-100;
        border-color: $gray-100;
        color: $secondary;

        &:hover {
            color: $secondary;
            background-color: $gray-200;
        }

        &:disabled {
            border-color: rgba($gray-100, 0.8);
            background-color: rgba($gray-100, 0.8);
        }

        &:focus {
            box-shadow: none;
        }
    }
}
