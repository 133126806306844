@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $amount, $color in $grays {
            .bg#{$infix}-gray-#{$amount} {
                background-color: $color;
            }
        }

        @each $amount, $color in $reds {
            .bg#{$infix}-red-#{$amount} {
                background-color: $color;
            }
        }
    }
}
