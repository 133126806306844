.znd-registration-sign-up {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    max-width: 51.75rem;
    width: 100%;
    margin: 0 auto;
    color: $secondary;

    @include media-breakpoint-up(sm) {
        padding: 1.25rem;
    }

    &__title {
        margin-left: 1.25rem;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
        }
    }

    &__container {
        padding: 2.5rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        background-color: $white;
        min-height: 19.875rem;
        position: relative;
        border-radius: 0.375rem;
    }

    &__subhead {
        margin-bottom: 2rem;
    }

    &__select-header {
        font-weight: $font-weight-bold;
        margin-bottom: 0.25rem;
    }

    &__country-select {
        height: 3.125rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            max-width: 21.375rem;
        }
    }

    &__reseller-select {
        max-width: 100%;
    }

    &__customer-title {
        margin-top: 3.75rem;
    }

    &__radio-content-container {
        margin-left: 3rem;
        margin-right: 3rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-up(sm) {
            width: 24.375rem;
        }
    }

    &__radio-container-unfolded {
        margin-top: -0.75rem;
    }

    &__radio-container-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        label {
            visibility: hidden;
            font-weight: $font-weight-bold;
            padding-top: 0.875rem;
            margin-left: 2rem;
            margin-right: 1rem;
        }

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            width: auto;

            label {
                visibility: visible;
            }

            [name='zip'] {
                max-width: 10rem;
            }
        }
    }

    &__serial-number-title {
        font-weight: $font-weight-bold;
        margin-bottom: 0.25rem;
    }

    &__customer-info-container {
        display: flex;
        flex-direction: row;
        margin-top: 0.75rem;
        margin-bottom: 1.5rem;
    }

    &__customer-info-icon {
        margin-right: 0.563rem;
        color: $gray-400;
    }

    &__customer-info-text {
        color: $gray-400;
    }

    &__customer-type-container {
        margin-bottom: 2.5rem;
        border: $input-border-width solid $input-border-color;
        border-radius: 0.375rem;

        @include media-breakpoint-up(sm) {
            margin-bottom: 9.375rem;
        }
    }

    &__customer-existing-container {
        border-bottom: $input-border-width solid $input-border-color;

        &:last-child {
            border-bottom: 0 none transparent;
        }
    }

    &__button {
        margin: 0 auto;

        &:disabled {
            background-color: $gray-100;
            color: $gray-300;
        }

        @include media-breakpoint-up(sm) {
            position: absolute;
            bottom: 2.5rem;
            right: 2.5rem;
        }
    }
}
