.znd-shop-select-address {
    &__subhead {
        margin-bottom: 1.25rem;
        color: $gray-500;

        &:last-child {
            margin-top: 2.75rem;
        }

        &--section {
            margin-top: 3rem;
        }
    }

    &__details {
        margin-top: 1.5rem;
    }

    &__loading {
        margin-top: 1.5rem;
        max-width: 16.25rem;
    }
}
