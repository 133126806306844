@use 'sass:color';
@use 'sass:map';

/*
 * PROJECT ONLY VARIABLES
 */
$navbarWidth: 5rem;
$mobileNavbarHeight: 3.875rem;

/*
 * BOOTSTRAP VARIABLES
 */
$zindex-dropdown: 1020;
$zindex-sticky: 1000;
// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23')) !default;

// Color system

$white: #ffffff;
$gray-100: #ececed;
$gray-200: #d1dae1;
$gray-300: #c4c8c8;
$gray-400: #74797c;
$gray-500: #212525;
$black: #000000;

$red: #e4032e;
$red-100: color.scale($red, $whiteness: 70%);
$red-200: color.scale($red, $whiteness: 60%);
$red-300: color.scale($red, $whiteness: 50%);
$red-400: color.scale($red, $whiteness: 40%);
$red-500: color.scale($red, $whiteness: 30%);
$red-600: color.scale($red, $whiteness: 20%);

$grays: () !default;
$grays: map.merge(
    (
        '100': $gray-100,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
    ),
    $grays
);

$reds: () !default;
$reds: map.merge(
    (
        '100': $red-100,
        '200': $red-200,
        '300': $red-300,
        '400': $red-400,
        '500': $red-500,
        '600': $red-600,
    ),
    $reds
);

// Removing the default bootstrap colors
$colors: () !global;

$primary: $red;
$secondary: #4c575d;
$light: $gray-100;
$dark: $gray-500;

$success: #4ce2a7;
$info: #f1a153;
$warning: $info;
$danger: #e24c4c;

$theme-colors: () !default;
$theme-colors: map.merge(
    (
        'primary': $primary,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
    ),
    $theme-colors
);

$button-colors: (
    'primary': (
        'background': $primary,
        'hoverBackground': $red-300,
        'color': $white,
        'activeBackground': $red-600,
        'disabledColor': $gray-500,
        'disabledBackground': $gray-200,
        'activeColor': $white,
        'hoverColor': $white,
    ),
    'secondary': (
        'background': $gray-100,
        'hoverBackground': $gray-200,
        'color': $primary,
        'activeBackground': $gray-300,
        'disabledColor': $gray-300,
        'disabledBackground': $gray-100,
        'activeColor': $primary,
        'hoverColor': $primary,
    ),
    'text': (
        'background': transparent,
        'hoverBackground': transparent,
        'color': $primary,
        'activeBackground': transparent,
        'disabledColor': $gray-300,
        'disabledBackground': transparent,
        'activeColor': $red-600,
        'hoverColor': $red-300,
    ),
    'light': (
        'background': $white,
        'hoverBackground': $white,
        'color': $gray-400,
        'hoverColor': $gray-500,
        'activeColor': $gray-500,
        'activeBackground': $white,
        'disabledColor': $gray-300,
        'disabledBackground': $white,
        'borderColor': $gray-400,
        'disabledBorderColor': $gray-400,
    ),
);

// Buttons

$btn-border-radius: 1.5rem;
$btn-border-radius-sm: 1.5rem;

$btn-padding-y: 0.875rem;
$btn-padding-x: 3.25rem;

$btn-font-size: 0.825rem;

$btn-padding-y-sm: 0.625rem;
$btn-padding-x-sm: 1rem;
$btn-font-size-sm: 0.825rem;

$btn-font-weight: 700;

$body-bg: $gray-100;
$body-color: $gray-500;

$text-muted: $gray-300;

// Elevation system

$elevation-shadow-100: 0 2px 5px rgba(0, 0, 0, 0.15);
$elevation-shadow-200: 0 3px 10px rgba(0, 0, 0, 0.15);
$elevation-shadow-300: 0 3px 15px rgba(38, 67, 89, 0.2);
$elevation-shadow-400: 0 3px 20px rgba(0, 0, 0, 0.15);
$elevation-shadow-500: 0 3px 25px rgba(0, 0, 0, 0.15);
$elevation-shadow-600: 0 3px 30px rgba(0, 0, 0, 0.15);
$elevation-shadow-action-button:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);

$elevation-shadows: (
    100: $elevation-shadow-100,
    200: $elevation-shadow-200,
    300: $elevation-shadow-300,
    400: $elevation-shadow-400,
    500: $elevation-shadow-500,
    600: $elevation-shadow-600,
);

// Typography
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: -apple-system, 'Segoe UI', 'Helvetica Neue', 'Arial', 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-base: 'IBMPlexSans', $font-family-sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 700;

$link-hover-decoration: none;

// Should only be used for calculations.
$font-size-root: 16px;

$font-size-base: 1rem;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

// Components
// Define common padding and border radius sizes and more.

$border-radius: 5px;
// $border-radius-lg:            .3rem !default;
// $border-radius-sm:            .2rem !default;

// Forms

$label-margin-bottom: 0.5rem !default;

$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-font-size: 1rem;
$input-font-weight: $font-weight-base;
$input-line-height: 1.5;

$input-bg: $gray-100;
$input-disabled-bg: $gray-100;
$input-disabled-color: $gray-300;
$input-disabled-border-color: $gray-100;

$input-color: $gray-500;
$input-border-color: $gray-200;
$input-border-width: 1px;
$input-box-shadow: none;

$input-border-radius: $border-radius !default;

$input-focus-bg: $input-bg;
$input-focus-border-color: $gray-400;
$input-focus-color: $input-color;
$input-focus-box-shadow: none;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $input-color;

$input-height-border: $input-border-width * 2;

$input-transition:
    color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

$custom-forms-transition: none;

$custom-range-track-width: 100%;
$custom-range-track-height: 0.375rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-200;
$custom-range-track-border-radius: 4px;
$custom-range-track-box-shadow: none;

$custom-range-thumb-width: 1.25rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $primary;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 100%;
$custom-range-thumb-box-shadow: $elevation-shadow-100;
$custom-range-thumb-focus-box-shadow: $elevation-shadow-100;
$custom-range-thumb-active-bg: $custom-range-thumb-bg;
$custom-range-thumb-disabled-bg: $input-disabled-color;

$custom-control-indicator-checked-color: $white;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill='#{$custom-control-indicator-checked-color}' d='M6.928 11.516l5.76-5.726c.2-.178.2-.368 0-.569l-.737-.737c-.201-.2-.39-.2-.57 0L6.628 9.172 4.652 7.129c-.201-.178-.391-.178-.57 0l-.77.77c-.2.18-.2.369 0 .57l3.047 3.047c.179.2.368.2.57 0z'/></svg>");

// Tooltips

$tooltip-font-size: 0.875rem;
$tooltip-max-width: 300px;
$tooltip-color: $white;
$tooltip-bg: $gray-500;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.375rem;
$tooltip-padding-x: 0.375rem;
$tooltip-margin: 0;

$tooltip-arrow-color: $tooltip-bg;
$tooltip-arrow-width: 1.625rem;
$tooltip-arrow-height: 0.875rem;
$tooltip-arrow-svg: escape-svg(
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 18 11'><path fill='#{$tooltip-arrow-color}' d='M10 10c-.6.6-1.4.6-2 0L.5 3.1c-1-1-.3-2.6 1-2.6h15c1.3 0 2 1.7 1 2.6l-7.5 7z'/></svg>")
);

/*
 * Grid breakpoints

 * Define the minimum dimensions at which your layout will change,
 * adapting to different screen sizes, for use in media queries.
 */
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    hg: 1440px,
);

$menu-breakpoint: xl;
$viewport-helper-breakpoints: $grid-breakpoints;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.25rem;

$modal-dialog-margin: 0;

$modal-content-color: $gray-500;
$modal-content-bg: $white;
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: $elevation-shadow-300;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.4;
$modal-header-border-color: transparent;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 2.5rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl: 1600px;
$modal-lg: 620px;
$modal-md: 400px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// Disable stylelint to allow webpack exports.
// stylelint-disable
:export {
    breakpoint_xs: map-get($grid-breakpoints, xs);
    breakpoint_sm: map-get($grid-breakpoints, sm);
    breakpoint_md: map-get($grid-breakpoints, md);
    breakpoint_lg: map-get($grid-breakpoints, lg);
    breakpoint_xl: map-get($grid-breakpoints, xl);
    breakpoint_hg: map-get($grid-breakpoints, hg);
    menu_breakpoint: $menu-breakpoint;
    body_color: $body-color;
    font_size_base: $font-size-base;
    font_family_base: $font-family-base;
    success_color: $success;
    danger_color: $danger;
    input_placeholder_color: $input-placeholder-color;
    input_border_color: $input-border-color;
    input_color: $input-color;
    gray_200: $gray-200;
    gray_400: $gray-400;
    gray_500: $gray-500;
    secondary: $secondary;
    primary: $primary;
}
// stylelint-enable
