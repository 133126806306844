.znd-product-list {
    @include media-breakpoint-between(sm, md) {
        flex-wrap: wrap;
    }

    &__headline-price {
        margin-bottom: 0.5rem;
        padding-right: 9.125rem;

        text-align: right;

        color: $gray-400;
    }

    &--cards {
        width: calc(100% + 1.25rem);
        margin-left: -0.625rem;
    }
}
