.znd-header-page {
    width: 100%;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
    }

    &__title {
        margin-bottom: 0.25rem;
        color: $secondary;
    }

    &__subtitle {
        color: $gray-500;
    }

    &-right {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 0.25rem;
        margin-top: 1.25rem;
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
            margin: 0;
        }
    }
}
