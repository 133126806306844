.znd-company-declined-modal {
    &__body {
        max-height: calc(100vh - 16.25rem);
    }

    &__footer {
        justify-content: space-between;
        flex-direction: column;
        align-items: stretch;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }
}
