.znd-table-head {
    display: flex;

    &__title {
        padding-right: 0.5rem;
    }

    &__icon {
        display: block;
        font-size: 1.25rem;
    }

    & .znd-tooltip__label {
        text-align: left;
        width: -moz-max-content;
        width: max-content;
    }
}

.rdt_TableCol_Sortable {
    &#{&} {
        overflow: visible;

        &:focus,
        &:hover {
            opacity: unset;

            & > div:not(.znd-table-head),
            & span,
            & i {
                opacity: 0.7;
            }
        }
    }
}
