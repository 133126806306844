.znd-shop-checkout-reseller {
    &__subhead {
        margin-bottom: 1.25rem;
        color: $gray-500;

        &::after {
            content: '*';
            color: $gray-400;
            position: relative;
            top: -2px;
            left: 2px;
        }
    }

    &__loading {
        margin-top: 1.5rem;
        max-width: 16.25rem;
    }
}
