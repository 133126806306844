.znd-cutter-tile {
    height: 100%;
    display: block;
    position: relative;
    padding: 1.25rem 1.25rem 0 1.25rem;
    background-color: $white;
    border-radius: 0.375rem;

    @include media-breakpoint-up(sm) {
        padding: 1.875rem;
    }

    &__title {
        margin-bottom: 1.25rem;
        color: $secondary;
    }

    &__image {
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1.25rem;
        object-fit: contain;
        max-height: 18.75rem;
    }

    &__button {
        margin: 0 0.25rem;
        z-index: 2;

        @include media-breakpoint-only(xs) {
            margin-bottom: 1.75rem;
        }
    }

    &__link-stretched {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
    }
}
