.znd-progress-stepper {
    display: flex;
    $component: &;

    &__step {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        color: $gray-400;
        width: 5.5rem;

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 0.5rem;
            transform: translateY(-50%);

            height: 1px;
            width: 100%;

            background-color: currentColor;
        }

        &:first-child {
            &::before {
                width: 50%;
                right: 0;
            }
        }

        &:last-child {
            &::before {
                width: 50%;
                left: 0;
            }
        }

        &--active {
            color: $primary;

            &::before {
                height: 3px;
            }
        }

        &--exact {
            &::before {
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
            }
        }

        @include media-breakpoint-up(md) {
            width: 11rem;
        }
    }
    &__dot {
        @include t-body-small;
        text-align: center;

        cursor: pointer;
        @at-root {
            #{$component}#{$component}--disabled & {
                cursor: auto;
            }
        }

        &::before {
            display: block;
            content: '';

            width: 1rem;
            height: 1rem;
            background-color: currentColor;

            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0.5rem;

            border-radius: 100%;
        }
    }
}
