.znd-dashboard-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 1.25rem;

    @include media-breakpoint-up(md) {
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }

    &__title {
        color: $secondary;
    }

    &__datetime {
        display: inline-block;
        margin-top: 0.25rem;
        font-size: 0.875rem;
        color: $gray-400;
    }

    &__cutter-and-shortcuts {
        padding-top: 2.5rem;
        align-items: stretch;
    }

    &__cutter {
        margin-bottom: 2.5rem;
        min-height: 4rem;
    }
}
