.znd-radio {
    $component: &;

    margin: 0;
    padding: 1.5rem;

    cursor: pointer;

    // Start of overwrite bootstrap classes for custom radio input
    // stylelint-disable selector-max-specificity
    & &__label {
        @include t-body-small;
        color: $secondary;
        font-weight: $font-weight-bold;

        &::before {
            top: 50%;
            transform: translateY(-56.25%);

            background-color: $white;
            border-color: $gray-200;
        }
    }

    & &__input:checked ~ .znd-radio__label {
        &::after {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;

            top: 50%;
            transform: translateY(-62.5%);
            left: -1.25rem;

            background-color: $primary;
            background-image: none;
        }

        &::before {
            background-color: $white;
            border: 2px $primary solid;
        }
    }

    &__large {
        padding: 0.5rem 1rem 0.5rem 0;

        &:first-child {
            padding-left: 0;
        }

        @include media-breakpoint-up(sm) {
            padding-left: 1rem;
        }

        #{$component}__label {
            font-size: 1rem;
            line-height: 1.5;
            font-weight: $font-weight-normal;
        }
    }

    // stylelint-enable selector-max-specificity
    // End of overwrite bootstrap classes for custom radio input
}
