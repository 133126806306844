@function elevation($key) {
    @return map-get($elevation-shadows, $key);
}

/**
 * @param {number} $amount The amount of elevation, e.g. `100`.
 */
@mixin elevate($amount) {
    box-shadow: elevation($amount);
}
