.znd-dashboard-disrupter-item {
    padding: 1.25rem 1.5rem;
    border-radius: 0.375rem;
    margin-right: 2rem;
    background-color: $secondary;
    color: $white;
    width: 100%;
    margin-top: 1.5rem;

    @include media-breakpoint-up(hg) {
        width: 50%;
        margin-top: 0;
    }

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
    }

    & + & {
        margin-right: 0;
    }

    &__content-wrapper {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__media {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        padding-top: 1.5rem;
        width: 24rem;
        max-width: 100%;

        @include media-breakpoint-up(md) {
            flex: 0 0 24rem;
            padding-top: 0;
            padding-left: 1.5rem;
            margin-left: auto;
        }
    }

    &__image,
    &__video {
        width: 100%;
    }

    &__content {
        *:last-child {
            margin-bottom: 0;
        }
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-weight: $font-weight-bold;
        margin-top: auto;

        &,
        &:hover {
            color: inherit;
        }

        i {
            font-size: 1em;
            padding-left: 0.5em;
        }
    }

    &__title + &__content,
    &__title + &__link {
        padding-top: 0.5rem;
    }

    &__content + &__link,
    &__image + &__link,
    &__video + &__link {
        padding-top: 1.25rem;
    }
}
