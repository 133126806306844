.znd-reseller-page {
    margin-top: 1.25rem;

    &__content {
        margin: 1.25rem;

        @include media-breakpoint-up(sm) {
            margin: 2.5rem;
        }
    }

    &__file-explorer {
        margin-left: 2.5rem;
        background: white;
    }

    &__title {
        margin-left: 2.5rem;
        padding: 7px;
    }

    p {
        padding: 4px;
        margin: 0;
    }
}
