.znd-tabs {
    $itemSpacing: 3rem;
    $itemPaddingX: 1rem;
    $itemMarginX: ($itemSpacing - (2 * $itemPaddingX)) / 2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    min-height: 3rem;
    border-bottom: 1px solid $gray-200;
    overflow-x: auto;
    overflow-y: hidden;
    @include smoothScroll;

    &--center {
        justify-content: center;
    }

    &__item-wrapper {
        position: relative;
        padding: 0 $itemMarginX;
    }

    &__item {
        flex: 0 0 auto;
        position: relative;
        display: block;
        cursor: pointer;

        font-size: 1.125rem;
        line-height: 1;
        color: $gray-500;
        white-space: nowrap;

        padding: 1rem 1rem 0.875rem;

        transition: color 0.2s ease-out;

        &::before {
            display: block;
            content: '';

            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 5px;

            background-color: $primary;

            opacity: 0;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            transition:
                opacity 0.2s ease-out,
                transform 0.2s ease-out;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $gray-500;
            background-color: $gray-100;
        }

        &#{&}--active {
            color: $primary;

            &::before {
                opacity: 1;
                transform: translateY(-100%);
            }
        }
    }

    &__arrow {
        color: $primary;
        font-size: 0.875rem;
        margin-left: 0.875rem;
    }

    &--small &__item {
        @extend %t-body;
    }

    &__category-navigation {
        top: 0;
        background-color: $gray-100;
        @include media-breakpoint-up(md) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}
