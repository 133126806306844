.znd-modal {
    $component: &;

    &__header {
        position: relative;
        border-bottom: 1px solid $gray-200;
    }

    &__title {
        @include t-card-title;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.25rem;
        color: $primary;

        width: 4rem;
        cursor: pointer;
    }

    &__body {
        padding: $modal-inner-padding $modal-header-padding-x;
    }

    &__footer {
        $padding-x: ($modal-header-padding-x - ($modal-footer-margin-between / 2));
        $padding-y: ($modal-inner-padding - ($modal-footer-margin-between / 2));
        padding: $padding-y $padding-x;
    }

    &__content {
        @include media-breakpoint-down(xs) {
            border-radius: 0;
            min-height: 100vh;
        }
    }

    .modal-xl {
        margin-top: 3.75rem;

        @include media-breakpoint-up(sm) {
            max-width: 100%;
            padding: 1.875rem 2.5rem;
        }

        @include media-breakpoint-up(xl) {
            max-width: calc(#{$modal-xl} + 5rem);
        }
    }

    &__scrollable {
        overflow-x: hidden;
        overflow-y: auto;

        .modal-dialog {
            .modal-body {
                @include media-breakpoint-down(xs) {
                    overflow-y: auto;
                }
            }
        }
    }

    &--as-desktop {
        // Automatically set modal's width for larger viewports
        .modal-dialog {
            max-width: $modal-md;
            margin: $modal-dialog-margin-y-sm-up auto;
        }

        .modal-content {
            @include box-shadow($modal-content-box-shadow-sm-up);
        }

        .modal-dialog-scrollable {
            max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

            .modal-content {
                max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
            }
        }

        .modal-dialog-centered {
            min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

            &::before {
                height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
                height: min-content;
            }
        }

        .modal-sm {
            max-width: 21.25rem;
        }

        #{$component} {
            &__content {
                min-height: 0;
                border-radius: $modal-content-border-radius;
            }

            @include media-breakpoint-down(xs) {
                &__body {
                    padding: 1.25rem;
                }
            }
        }
    }
}
