* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    font-size: $font-size-root; // ensures 1rem == 16px
}

:root {
    color-scheme: light;
}

@supports (color-scheme: only light) {
    :root {
        color-scheme: only light;
    }
}
