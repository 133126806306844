.znd-product-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: $white;
    padding: 1.25rem;
    border-radius: $border-radius;
    overflow: hidden;

    &-wrapper {
        display: flex;
        padding: 0.625rem;

        flex: 0 0 100%;
        max-width: none;
        width: 100%;

        @each $breakpoint, $size in (md: 50%, xl: 25%, hg: 16.66%) {
            @include media-breakpoint-up($breakpoint) {
                flex: 0 0 $size;
                max-width: $size;
                width: auto;
            }
        }
    }

    &__image-wrapper {
        position: relative;

        border: 1px solid $gray-200;
        border-radius: $border-radius;
        overflow: hidden;

        &::before {
            display: block;
            content: '';
            padding-top: 52%;
        }
    }

    &__image {
        @include stretched();

        object-fit: contain;
        object-position: center;
    }

    &__title {
        @extend %t-subhead-1;
        color: $gray-500;
        margin-top: 1.25rem;
    }

    &__description {
        @extend %t-body-small;
        color: $gray-500;
        margin-top: 0.375rem;
    }

    &__footer {
        margin-top: auto;
    }

    &__price {
        @extend %t-body;
        color: $gray-500;
        margin-top: 1.875rem;
    }

    &__price-info {
        @extend %t-body-small;
        color: $gray-400;
        margin-top: 0.375rem;
        margin-bottom: 1.25rem;
    }
}
