.znd-native-select {
    $component: &;
    $cartLineItemQuantityModifier: #{$component}--cart-line-item-quantity;

    $padding: 0.875rem;
    $cartLineItemQuantityHorizontalPadding: 0.5rem;
    $cartLineItemQuantityPadding: 0.25rem #{$cartLineItemQuantityHorizontalPadding};

    position: relative;
    padding: $input-padding-y 0;
    height: $input-height;
    font-size: $input-font-size;
    line-height: $input-line-height;

    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    border-radius: $input-border-radius;

    @at-root {
        #{$component}#{$cartLineItemQuantityModifier} {
            padding: 0;
            line-height: 1.25;
            width: 6.25rem;
            height: 2rem;
        }
    }

    @include media-breakpoint-up(xl) {
        max-width: 16.25rem;
    }

    &:hover {
        border-color: $input-focus-border-color;
    }

    &__select {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        appearance: none;
        overflow: hidden;
        opacity: 0;
    }

    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;

        font-size: 1.25rem;

        right: $padding;
        color: $primary;
        #{$component}#{$cartLineItemQuantityModifier} & {
            right: $cartLineItemQuantityHorizontalPadding;
        }
    }

    &__label {
        width: 100%;
        margin: 0;
        padding-left: $input-padding-x;
        padding-right: $input-height;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        color: $input-placeholder-color;

        &--selected {
            color: $input-color;
        }

        @at-root {
            #{$component}#{$cartLineItemQuantityModifier} & {
                padding: $cartLineItemQuantityPadding;
                padding-right: 2rem;
                width: 100%;
                text-align: center;
            }
        }
    }
}
