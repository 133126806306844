.znd-cutter-slider {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &__title {
        color: $gray-500;
        margin-bottom: 0.625rem;
    }
}
