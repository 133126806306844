.znd-offers-table {
    overflow-y: scroll;

    &#{&} &__checkout {
        margin: 0.75rem 0;
        padding: 0.625rem 2.75rem 0.625rem 1rem;

        // Overwrite custom button style of table.
        &--button:hover {
            background-color: $gray-200;
        }
    }

    &__sub-table {
        margin: 0 3.25rem 0 auto;

        &__row td {
            color: $gray-500;

            line-height: 1.25rem;
            font-size: 0.875rem;
            padding: 0.25rem 0;

            &:first-child {
                padding-right: 1.5rem;
            }

            &:last-child {
                text-align: right;
            }
        }

        &__image {
            @include stretched();

            object-fit: contain;

            &-wrapper {
                position: relative;
                flex: 0 0 auto;
                width: 7.75rem;
                height: 4rem;

                border: 1px solid $gray-200;
                border-radius: 0.25rem;
                margin: 1rem 1rem 1rem 0;
            }
        }
    }
}
