.znd-software-page {
    &__overview {
        margin: 1.25rem;

        @include media-breakpoint-up(md) {
            margin: 2rem 2.5rem;
        }

        &-header {
            margin-bottom: 1rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 1.75rem;
            }
        }
    }

    &__bottom-read-more {
        margin-top: 0;

        &,
        & i {
            font-size: 1rem;
        }
    }

    &__full-description {
        color: $gray-500;
        margin-bottom: 1rem;

        h1,
        h2,
        h3 {
            color: $secondary;
        }

        h1 {
            @extend %t-headline-1;
        }

        h2 {
            @extend %t-headline-2;
        }

        h3 {
            @extend %t-headline-3;
        }
    }

    &__buttons {
        margin-top: 2.25rem;
    }
}
