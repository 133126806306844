.znd-orders-table-sub {
    margin: 1rem 1rem 1.5rem 4.125rem;

    &__image {
        @include stretched();

        object-fit: contain;

        &-wrapper {
            position: relative;
            flex: 0 0 auto;
            width: 7.75rem;
            height: 4rem;

            border: 1px solid $gray-200;
            border-radius: 0.25rem;
            margin: 1rem 1rem 1rem 0;
        }
    }

    &__table {
        flex: 1 1 auto;
    }

    &__prices {
        margin: 0 3.25rem 0 auto;

        &-total td {
            font-weight: $font-weight-bold;
            color: $secondary;
        }

        &-sub td {
            color: $gray-500;
        }

        &-total td,
        &-sub td {
            line-height: 1.25rem;
            font-size: 0.875rem;
            padding: 0.25rem 0;

            &:first-child {
                padding-right: 1.5rem;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    &__column {
        line-height: 1.25rem;
        font-size: 0.875rem;
        min-width: 20%;
        max-width: 40%;
        width: auto;

        &-label {
            font-weight: $font-weight-bold;
            color: $secondary;
        }

        &-comment {
            max-width: 31.25rem;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(hg) {
            min-width: auto;
            max-width: none;
            width: 16.25rem;
        }
    }

    &__info-box {
        width: 30rem;
        max-width: none;
    }
}
