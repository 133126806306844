.znd-shop-page {
    &__category-navigation {
        top: 0;
        z-index: 2;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: $gray-100;
    }

    &__product {
        padding: 1.5rem;
        background: #ffffff;
        border-radius: 0.375rem;
        display: flex;
        flex-direction: row;
        align-items: stretch;

        &__img {
            flex: 0 0 40%;
            width: 40%;
            margin-right: 1rem;

            @include media-breakpoint-up(md) {
                display: none;
            }

            @include media-breakpoint-up(lg) {
                display: block;
            }

            img {
                border: 1px solid #d1dae1;
                border-radius: 5px;
                width: 100%;
                height: auto;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__content-description {
            margin-bottom: 1rem !important;
        }

        &__content-title {
            font-weight: bold;
        }
    }
}
