.znd-asset {
    z-index: 0;
    position: relative;

    &--scrollable {
        overflow-x: auto;
        overflow-y: hidden;
    }

    &__check {
        font-size: 1.25rem;
        color: $primary;
    }
}
