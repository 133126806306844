.znd-number-stepper {
    position: relative;

    width: 6.25rem;
    height: 2rem;

    &__icon-button {
        top: 50%;
        transform: translateY(-50%);

        cursor: pointer;

        color: $primary;

        width: 2rem;
        height: 110%;

        &.--plus {
            right: -0.125rem;

            & .icon {
                font-size: 1.125rem;
                top: unset;
            }

            & .icon-plus {
                left: 0.25em;
            }
        }

        &.--minus {
            left: -0.125rem;

            & .icon-minus {
                left: 0.25rem;
            }
        }
    }

    &__input {
        height: 100%;
        padding: 0.375rem 1.5rem;

        text-align: center;

        /* Hide browser specific number stepper */
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
