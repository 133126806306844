.znd-shop-product-page {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include media-breakpoint-up(md) {
        padding-top: 2rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }

    &__back-button {
        font-size: 1rem;
        font-weight: $font-weight-normal;
    }

    &__bottom {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        padding-bottom: 1.25rem;
        margin-bottom: 2.5rem;

        &.divider-line {
            border-top: 1px solid $gray-200;
        }

        &-read-more {
            margin-top: 3.75rem;

            &,
            & i {
                font-size: 1rem;
            }
        }

        div + &-read-more {
            margin-top: 2rem;
        }

        @include media-breakpoint-up(md) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            padding-bottom: 2.5rem;
        }
    }

    &__content {
        margin-top: 1.25rem;

        background-color: $white;
        border-radius: $border-radius;

        @include media-breakpoint-up(md) {
            margin-top: 2rem;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 2.625rem;
        }

        &.--no-margin {
            margin-top: 0;
        }

        &.contain-description {
            max-width: 52.5rem;
            margin-top: 3.75rem;
            color: $gray-500;
        }

        &-table {
            overflow: auto;
            color: $gray-500;

            table {
                position: relative;
                width: 62rem;

                @include media-breakpoint-up(lg) {
                    width: 100%;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: calc(100% - 0.125rem);
                    height: calc(100% - 0.125rem);
                    top: 1px;
                    left: 1px;
                    border-collapse: collapse;
                    border-radius: 0.375rem;
                    border-style: hidden;
                    box-shadow: 0 0 0 1px $gray-200;
                }

                thead {
                    white-space: nowrap;
                    font-size: 1rem;
                    line-height: 1.375rem;
                    color: $secondary;
                }

                th,
                td {
                    padding: 1.25rem;
                }

                th.disable-nowrap {
                    white-space: normal;
                }

                td {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $gray-500;

                    > i {
                        font-size: 1.75rem;
                    }
                }

                tr {
                    border-bottom: 1px solid $gray-200;
                }

                tbody tr:last-child {
                    border: none;
                }
            }
        }

        &-table,
        &.contain-description {
            h1 {
                @extend %t-headline-1;
            }

            h2,
            h3 {
                @extend %t-headline-3;
            }
        }
    }

    &__top {
        display: flex;
        flex-direction: column;

        padding: 1.25rem 1.25rem 3.75rem;

        @include media-breakpoint-up(md) {
            padding: 2.5rem 2.5rem 5rem;
            flex-direction: row;
            align-items: flex-start;
        }

        @include media-breakpoint-up(lg) {
            padding: 2.5rem 2.5rem 5.625rem;
        }
    }

    &__image-wrapper {
        position: relative;

        border: 1px solid $gray-200;
        border-radius: $border-radius;
        overflow: hidden;

        margin-bottom: 1rem;

        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            margin-right: 2.5rem;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 46%;
            margin-right: 5rem;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 35%;
        }

        &::before {
            display: block;
            content: '';
            padding-top: 100%;
        }
    }

    &__info {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        width: 100%;
        height: 100%;

        object-fit: contain;
    }

    &__title {
        color: $secondary;
        margin-bottom: 0.625rem;
    }

    &__product-description {
        color: $gray-500;
        margin-bottom: 1.5rem;
    }

    &__product-number {
        color: $gray-400;
        margin-bottom: 1.5rem;
    }

    &__product-quantity-title,
    &__product-price-title {
        color: $gray-500;
        margin-bottom: 0.625rem;
    }

    &__product-price {
        color: $secondary;
    }

    &__variant-option {
        margin-top: 2.5rem;
    }

    &__product-price-per-unit {
        @extend %t-subhead-2;
        color: $gray-400;
    }

    &__product-price-info {
        color: $gray-400;
        margin-bottom: 2.5rem;
    }

    &__product-quantity {
        margin-top: 2.5rem;
        margin-right: auto;

        min-width: 15rem;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }

    &__product-quantity-title {
        margin-bottom: 0.375rem;
    }

    &__product-quantity-submit {
        margin-top: 1rem;
    }

    &__tabs {
        margin-left: -1.25rem;
        margin-right: -1.25rem;
        margin-bottom: 2.5rem;

        @include media-breakpoint-up(md) {
            margin-left: -2.5rem;
            margin-right: -2.5rem;
        }
    }

    &__meta {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            flex-direction: row;

            margin-left: -2.5rem;
            margin-right: -2.5rem;
        }
    }

    &__meta-group {
        padding-bottom: 1.875rem;

        & + & {
            padding-top: 1.875rem;
            border-top: 1px solid $gray-200;
        }

        @include media-breakpoint-down(xl) {
            &:last-child {
                padding-bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 50%;
            padding: 0 2.5rem;

            & + & {
                padding-top: 0;
                border-top: none;
            }

            &:nth-child(even) {
                border-left: 1px solid $gray-200;
            }
        }
    }

    &__meta-group-title {
        @extend %t-subhead-1;
        color: $secondary;
        margin-bottom: 1.25rem;

        @include media-breakpoint-up(xl) {
            margin-bottom: 2.5rem;
        }
    }

    &__applications {
        display: flex;
        flex-direction: row;
        overflow: auto;
        @include smoothScroll;

        @include media-breakpoint-up(md) {
            margin-left: -2.5rem;
            margin-right: -2.5rem;
        }

        @include media-breakpoint-up(xl) {
            flex-wrap: wrap;
            margin-left: -1rem;
            margin-right: -1rem;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__application {
        flex: 0 0 90%;

        & + & {
            margin-left: 1.25em;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 39%;

            margin-left: 2.5rem;
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 50%;

            padding-left: 1rem;
            padding-right: 1rem;

            margin: 0;

            & + & {
                margin-left: 0;
            }

            &:nth-child(n + 3) {
                margin-top: 2.5rem;
            }
        }
    }

    &__application-scroll-helper {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            width: 2.5rem;
            flex: 0 0 2.5rem;
            height: 1px;
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    &__application-image {
        position: relative;
        padding-top: 52%;
        margin-bottom: 1rem;

        border: 1px solid $gray-200;
        border-radius: $border-radius;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    &__application-title {
        @extend %t-body-small;
        font-weight: $font-weight-bold;
        color: $gray-500;
    }

    &__specifications {
        @include media-breakpoint-up(md) {
            column-count: 2;
        }
        @include media-breakpoint-up(xl) {
            column-count: auto;
        }
    }

    &__specification {
        & + & {
            margin-top: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
            display: flex;
            flex-direction: row;

            & + & {
                margin-top: 2.5rem;
            }
        }
    }

    &__specification-title {
        flex: 0 0 35%;
        @extend %t-body-small;
        font-weight: $font-weight-bold;
        margin-bottom: 0.5rem;
        color: $gray-500;

        @include media-breakpoint-up(xl) {
            padding-right: 2.5rem;
            margin-bottom: 0;
        }
    }

    &__specification-description {
        @extend %t-body;
        color: $gray-500;
    }

    &__specification-description-line {
        display: block;
    }

    &__related-products {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
    }

    &__related-products-title {
        @extend %t-body-tiny;
        color: $gray-500;
        margin-bottom: 1rem;
    }

    &__related-products-list {
        display: flex;
        flex-direction: row;
        overflow: auto;
        @include smoothScroll;

        margin-left: -1.25rem;
        margin-right: -1.25rem;

        @include media-breakpoint-up(md) {
            margin-left: -2.5rem;
            margin-right: -2.5rem;
        }
    }

    &__related-products-item {
        display: flex;
        padding: 0 0.625rem;

        flex: 0 0 85%;
        max-width: 85%;

        &:first-child {
            margin-left: 0.625rem;
        }

        &-helper {
            flex: 0 0 auto;
            width: 0.625rem;
            height: 1px;

            @include media-breakpoint-up(md) {
                width: 1.875rem;
            }
        }

        @each $breakpoint, $size in (md: 40%, lg: 35%, xl: 25%, hg: 20%) {
            @include media-breakpoint-up($breakpoint) {
                flex: 0 0 $size;
                max-width: $size;

                &:first-child {
                    flex: 0 0 $size;
                    max-width: $size;
                    margin-left: 1.875rem;
                }
            }
        }
    }

    &__bulk-price {
        width: 100%;

        &-wrapper {
            width: 75%;
            margin-top: 2.5rem;
            border: 1px solid $gray-200;
            border-radius: $border-radius;

            @include media-breakpoint-up(md) {
                width: 100%;
            }

            @include media-breakpoint-up(lg) {
                width: auto;
                margin-right: auto;
                min-width: 15rem;
            }

            @include media-breakpoint-up(xl) {
                margin-top: 0;
                margin-left: 2.5rem;
            }
        }

        &-title {
            color: $gray-400;
        }

        &-cell {
            padding: 0.5rem 0.75rem 0.375rem;

            @include t-body-tiny;
        }

        &-row {
            border-top: 1px solid $gray-200;
        }
    }

    & &__subscription-options {
        &-title {
            color: $gray-500;
        }

        &-compare {
            cursor: pointer;
            color: $primary;
        }

        &-radios {
            display: block;

            @include media-breakpoint-up(sm) {
                display: flex;
            }
        }
    }

    & &__additional-user-title {
        color: $gray-500;
    }

    &__subscription-options + &__additional-user {
        margin-top: 1.5rem;
    }

    &__machine-selection {
        width: max-content;
        color: $gray-500;

        &-item {
            @include t-body;
            margin: 0;

            .custom-control-label {
                &::before,
                &::after {
                    top: 0;
                }
            }

            & + & {
                margin-top: 0.875rem;
            }

            label {
                cursor: pointer;
            }
        }
    }

    &__info-text {
        color: $gray-500;
    }
}
