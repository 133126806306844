.znd-footer {
    background: $white;
    box-shadow: 0 -1px 0 $gray-200;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.875rem 0.625rem;

    @include media-breakpoint-down(sm) {
        padding: 2.5rem 0.625rem;
    }
}

.znd-footer-navigation {
    text-align: center;

    &__item {
        color: $primary;
        padding: 0.625rem 1.875rem;
        display: inline-block;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: $font-weight-bold;

        @include media-breakpoint-down(sm) {
            display: block;
            padding: 0.875rem 0;
        }
    }
}
