.znd-cart-list-item {
    flex: 0 0 80%;
    width: 100%;
    position: relative;

    padding: 1.25rem;
    padding-right: 3.5rem;

    color: $gray-500;

    border-bottom: 1px $gray-200 solid;

    @include cartLineItemOnDesktop {
        padding: 1.25rem;
    }

    &__content-wrapper {
        padding-left: 1rem;
        width: calc(100% - 6.25rem);

        @include cartLineItemOnDesktop {
            padding-left: 0;
            width: calc(100% - 7.25rem);
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        object-fit: contain;

        &-wrapper {
            position: relative;
            flex: 0 0 auto;

            width: 6.25rem;
            height: 3.25rem;

            border: 1px solid $gray-200;
            border-radius: 0.25rem;

            @include cartLineItemOnDesktop {
                width: 7.75rem;
                height: 4rem;
            }
        }
    }

    &__text-wrapper {
        flex: 1 1 auto;
        flex-direction: column;

        @include cartLineItemOnDesktop {
            flex: 1 1 8.75rem;
            min-width: 8.75rem;
            padding: 0 1.25rem;
        }

        @include media-breakpoint-up(xl) {
            min-width: 15rem;
            padding-right: 2.5rem;
        }
    }

    &__title {
        color: $secondary;

        &:hover {
            color: $secondary;
        }
    }

    &__description {
        display: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: $gray-500;
        padding-top: 0.25rem;
        max-width: 21rem;

        @include media-breakpoint-up(sm) {
            display: block;
        }
    }

    &__price {
        flex: 0 0 auto;
        padding: 1.75rem 0 1.5rem;
        white-space: nowrap;
        color: $gray-500;

        @include cartLineItemOnDesktop {
            padding: 0 2.5rem 0 1.25rem;
        }

        &-per-unit {
            color: $gray-400;
            margin-left: 0.25rem;
            line-height: 1.375rem;

            @include cartLineItemOnDesktop {
                margin: 0;
                line-height: 1.25rem;
            }
        }

        &-total {
            display: flex;
            justify-content: flex-start;

            @include cartLineItemOnDesktop {
                flex: 0 0 7rem;
                padding-right: 0.5rem;
                margin-left: 1.125rem;

                text-align: right;
            }

            @include media-breakpoint-up(lg) {
                justify-content: flex-end;
            }

            @include media-breakpoint-up(xl) {
                flex: 0 0 7.75rem;
            }
        }
    }

    &__number-stepper {
        padding-bottom: 0.75rem;
        padding-top: 1.25rem;

        @include cartLineItemOnDesktop {
            padding: 0 2.75rem 0 0;
            flex: 0 0 auto;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 3.75rem 0 1.25rem;
        }
    }

    & &__button-trash {
        position: absolute;
        top: 1.75rem;
        right: 2.5rem;

        @include cartLineItemOnDesktop {
            position: relative;
            top: unset;
            right: unset;
        }

        .btn__loader {
            right: unset;
        }
    }
}
