.znd-dropdown {
    position: fixed;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-width: 18.75rem;
    max-width: 100vw;
    margin-top: -3px;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    background-color: $white;

    @include elevate(200);
    border-radius: $border-radius;

    transform: translateY(-1rem);
    opacity: 0;
    visibility: hidden;
    transition:
        transform 0.2s ease-out,
        opacity 0.2s ease-out,
        visibility 0.2s step-end;

    &--visible {
        position: fixed;
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        transition:
            transform 0.2s ease-out,
            opacity 0.2s ease-out,
            visibility 0.2s step-start;
    }

    &__item {
        font-size: 1rem;
        line-height: 1.5rem;
        color: $gray-500;
        padding: 0.625rem 1.25rem;

        transition:
            background-color 0.2s ease-out,
            color 0.2s ease-out;

        @include media-breakpoint-up(sm) {
            white-space: nowrap;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $gray-500;
            background-color: $gray-100;
        }

        &--active {
            color: $primary;
        }
    }
}
