@mixin navbarOnDesktop() {
    @include media-breakpoint-up($menu-breakpoint) {
        @content;
    }
}

@mixin cartLineItemOnDesktop() {
    @include media-breakpoint-up(lg) {
        @content;
    }
}

@mixin smoothScroll {
    -webkit-overflow-scrolling: touch;
}
