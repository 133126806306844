.znd-table-filter-dropdown {
    padding: 1.25rem;
    min-width: 20rem;

    &__separator {
        margin: 1rem -1.25rem;
    }

    &__option-row {
        & + & {
            margin-top: 1rem;
        }
    }

    &__checkbox {
        margin: 0;
    }
}
