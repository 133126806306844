.znd-typeahead-input {
    position: relative;

    > input {
        padding-right: 2.75rem;

        &[readonly] {
            color: $gray-500;
            border-color: $gray-200;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 1.625rem;
        transform: translateY(-50%);

        line-height: 1;
        font-size: 1.125rem;

        pointer-events: none;
        color: $primary;
    }

    &__options {
        display: none;
        padding-left: 0;
        -webkit-overflow-scrolling: touch;
        position: absolute;
        top: 100%;
        left: 0;

        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        width: 100%;
        border-radius: 0.375rem;
        box-shadow: $elevation-shadow-200;

        z-index: $zindex-dropdown;

        overflow-y: scroll;
        max-height: 15.75rem;

        background-color: $white;

        @include media-breakpoint-up(xl) {
            width: 100%;
        }

        &-item {
            padding: 0.375rem 0.75rem;
            margin: 0.5rem;
            cursor: pointer;
            display: block;
            color: $gray-500;

            &:hover,
            &--selected {
                background-color: $gray-100;
                border-radius: 0.375rem;
            }
        }

        &--visible {
            display: block;
        }
    }
}
