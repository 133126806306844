.znd-assets-table-sub {
    margin: 1rem 1rem 1.5rem 4.125rem;
    width: fit-content;
    max-width: 100%;

    &__image {
        width: 9.5rem;
        height: 10.125rem;
        object-fit: contain;
        border: 1px solid $gray-200;
        padding: 0.75rem;
        margin-right: 1rem;
        border-radius: 0.375rem;
    }

    &__table {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        max-width: calc(100% - 3.125rem);
    }

    &__buttons {
        display: flex;
        flex-flow: wrap;
        width: 100%;

        .btn {
            margin: 1.25rem 0.75rem 0 0;
        }
    }

    &__button-wrapper {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    &__info {
        font-size: 0.75rem;
        margin-top: 0.5rem;
    }

    &__filename {
        color: $secondary;

        .btn__icon-right {
            color: $primary;
        }
    }
}
