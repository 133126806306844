.znd-shop-checkout-radio {
    $component: &;

    margin: 0;
    padding: 1.25rem;

    cursor: pointer;

    &__wrapper {
        margin-bottom: 0.75rem;
        border: 1px solid $gray-200;
        border-radius: 0.375rem;

        transition: background-color 0.2s ease-out;

        &:hover {
            background-color: rgba($gray-100, 0.4);
        }
    }

    &__wrapper.--loading {
        #{$component}__image {
            padding-left: 0;
        }
    }

    &__image {
        width: auto;
        height: 1.625rem;
        padding-left: 1.5rem;
    }

    &__loading {
        width: 1.5rem;
        height: 1.5rem;
        flex: 0 0 1.5rem;
    }

    &__additional-fields {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0.875rem 1.25rem;

        @include media-breakpoint-up(xl) {
            padding-left: add(add(0.875rem, $custom-control-gutter), $custom-control-indicator-size);
            padding-right: 20%;
        }
    }

    &__additional-field {
        &--column {
            padding-left: 0.375rem;
            padding-right: 0.375rem;

            flex: 1 1 auto;
            width: 100%;

            & + & {
                margin-top: 0.75rem;
            }

            @include media-breakpoint-between(sm, md) {
                & + & {
                    margin-top: 0;
                }
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include media-breakpoint-up(xl) {
                & + & {
                    margin-top: 0;
                }
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    // Start of overwrite bootstrap classes for custom radio input
    // stylelint-disable selector-max-specificity
    & &__label {
        @include t-subhead-1;
        color: $gray-500;

        &::before {
            top: 50%;
            transform: translateY(-56.25%);

            background-color: $white;
            border-color: $gray-200;
        }
    }

    & &__input:checked ~ .znd-shop-checkout-radio__label {
        &::after {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;

            top: 50%;
            transform: translateY(-62.5%);
            left: -1.25rem;

            background-color: $primary;
            background-image: none;
        }

        &::before {
            background-color: $white;
            border: 2px $primary solid;
        }
    }
    // stylelint-enable selector-max-specificity
    // End of overwrite bootstrap classes for custom radio input
}
