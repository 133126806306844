.znd-shop-checkout-summary-order-data {
    margin-bottom: 2.5rem;

    &__column {
        flex: 0 0 50%;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            flex: 0 0 45%;
        }
    }
}
