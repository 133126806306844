// Paths need to be relative to the resulting `app.css` of the FE workflow build.

@font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSans-Regular'), url(../fonts/ibmplexsans/IBMPlexSans-Regular.ttf);
    font-weight: $font-weight-normal;
    font-style: normal;
}
@font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSans-Bold'), url(../fonts/ibmplexsans/IBMPlexSans-Bold.ttf);
    font-weight: $font-weight-bold;
    font-style: normal;
}
@font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSans-Italic'), url(../fonts/ibmplexsans/IBMPlexSans-Italic.ttf);
    font-weight: $font-weight-normal;
    font-style: italic;
}
@font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSans-BoldItalic'), url(../fonts/ibmplexsans/IBMPlexSans-BoldItalic.ttf);
    font-weight: $font-weight-bold;
    font-style: italic;
}
