/*
 * Alternate buttons
 */

@each $variant, $value in $button-colors {
    $background: map_get($value, 'background');
    $hoverBackground: map_get($value, 'hoverBackground');
    $color: map_get($value, 'color');
    $activeBackground: map_get($value, 'activeBackground');
    $disabledColor: map_get($value, 'disabledColor');
    $disabledBackground: map_get($value, 'disabledBackground');
    $hoverColor: map_get($value, 'hoverColor');
    $activeColor: map_get($value, 'activeColor');
    $borderColor: map_get($value, 'borderColor');
    $disabledBorderColor: map_get($value, 'disabledBorderColor');

    .btn-#{$variant} {
        @include custom-button-variant(
            $color,
            $background,
            $hoverBackground,
            $hoverColor,
            $activeBackground,
            $activeColor,
            $disabledBackground,
            $disabledColor,
            $borderColor,
            $borderColor,
            $borderColor,
            $disabledBorderColor
        );
    }
}

.btn-text {
    padding: 0;
}
