.znd-contact-person {
    &__icon {
        color: $primary;
        margin-right: 0.5rem;
        margin-top: -1px;
    }

    &__link {
        color: $gray-500;
    }

    &__details {
        color: $gray-500;
    }

    &__name {
        padding-right: 0.375rem;
        word-break: break-word;
        font-weight: $font-weight-bold;
    }

    &__actions {
        display: flex;
        margin-left: auto;
    }

    &__button {
        font-size: 0.875rem;

        i {
            font-size: 1.25rem !important;
            margin-left: 0.5rem;
        }
    }

    &__roles {
        color: $gray-500;

        td {
            padding-bottom: 0.375rem;
        }
    }
}
