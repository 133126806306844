@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $amount, $shadow in $elevation-shadows {
            .elevation#{$infix}-#{$amount} {
                box-shadow: $shadow !important;
            }
        }
    }
}
