.znd-notification-item {
    $component: &;

    border-bottom: 1px solid $gray-100;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $gray-500;

    padding: 1.25rem 1.5rem;

    @include t-body;

    &:hover {
        cursor: default;
    }

    &__label {
        text-align: left;
    }

    &--link {
        &:hover {
            background-color: $gray-100;
            color: $secondary;
            cursor: pointer;

            #{$component}__icon-info {
                background-color: $primary;
            }
        }
    }
}
