.znd-notifications-dropdown {
    position: absolute;
    top: 100%;
    left: auto;
    right: -0.75rem;
    bottom: auto;

    transform: translateY(1.25rem);

    z-index: $zindex-modal-backdrop;
    min-width: 26.25rem;
    min-height: 25rem;

    border-radius: 0.375rem;
    background-color: $white;

    @include elevate(200);

    @include navbarOnDesktop {
        right: 0;
    }

    &::before {
        display: block;
        content: '';

        position: absolute;
        top: 100%;
        left: 1.5rem;

        transform: translateX(-50%) translateY(-0.125rem);

        width: 0;
        border: 0.625rem solid transparent;
        border-top: 0.75rem solid $white;

        @include media-breakpoint-up(sm) {
            top: auto;
            left: auto;
            right: 1.5rem;
            bottom: 100%;

            border: 0.625rem solid transparent;
            border-bottom: 0.75rem solid $white;

            transform: translateX(50%) translateY(2px);
        }
    }

    &__title {
        padding: 0.75rem 1.25rem;
        border-bottom: 1px solid $gray-100;
        color: $gray-500;
        text-align: left;
    }

    &__item-wrapper {
        max-height: 21rem;
        overflow-y: scroll;
    }
}
