.znd-user-button {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;

    &__name {
        padding-left: 0.625rem;

        @include navbarOnDesktop() {
            display: none;
        }
    }

    &__dropdown {
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: $zindex-dropdown;
        min-width: 15rem;

        transform: translateY(-1.25rem);

        background-color: $white;
        @include elevate(200);

        @include navbarOnDesktop {
            @supports (filter: drop-shadow($elevation-shadow-200)) {
                filter: drop-shadow($elevation-shadow-200);
                box-shadow: none;
            }
        }

        padding: 0.75rem 0;
        border-radius: $border-radius;

        &::before {
            display: block;
            content: '';

            position: absolute;
            top: 100%;
            left: (3.125rem / 2);

            transform: translateX(-50%) translateY(-2px);

            width: 0;
            border: 0.625rem solid transparent;
            border-top: 0.75rem solid $white;

            @include navbarOnDesktop {
                top: auto;
                left: auto;
                right: (3.125rem / 2);
                bottom: 100%;

                border: 0.625rem solid transparent;
                border-bottom: 0.75rem solid $white;

                transform: translateX(50%) translateY(2px);
            }
        }

        @include navbarOnDesktop {
            top: 100%;
            left: auto;
            right: 0;
            bottom: auto;

            transform: translateY(1.25rem);
        }

        &__title {
            padding: 0.75rem 1.25rem;
            border-bottom: 1px solid $gray-100;
        }
    }

    &__dropdown-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        padding: 0.75rem 1.25rem;

        @include t-body;

        &,
        &:hover,
        &:focus,
        &:active {
            color: $gray-500;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($gray-100, 0.4);
        }

        i {
            color: $gray-300;
            font-size: 1.25rem;
            margin-right: 0.5rem;
        }
    }
}
